if (!!window.IntersectionObserver) {
    let observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.getAttribute('data-iframe')) {
                        const iframe = document.createElement('iframe');
                        iframe.setAttribute('src', entry.target.dataset.iframe);
                        entry.target.appendChild(iframe);

                        iframe.addEventListener('load', function () {
                            this.parentNode.classList.add('loaded');
                        });
                    }

                    if (entry.target.getAttribute('data-src')) {
                        const img = document.createElement('img');
                        img.setAttribute('src', entry.target.dataset.src);
                        img.setAttribute('srcset', entry.target.dataset.srcset);
                        img.setAttribute('alt', entry.target.dataset.alt);
                        img.setAttribute('sizes', entry.target.dataset.sizes);
                        entry.target.appendChild(img);

                        img.addEventListener('load', function () {
                            entry.target.classList.add('loaded');
                        });
                    }

                    observer.unobserve(entry.target);
                }
            });
        },
        { rootMargin: '0px -100px 50px 0px' }
    );
    document.querySelectorAll('.lazy').forEach((img) => {
        observer.observe(img);
    });
}
